<template>
    <div style="width: 100%; overflow: hidden; position: relative;" id="parent">
        <div class="left_tab_parent">
            <div class="left_tab">
                <div v-for="(item, index) in tab_list" :key="index" :class="{ isChoose: chooseIndex==index }"
                     @click="showTab(index)">{{ item.name }}
                </div>
            </div>
        </div>
        <div class="right_content_parent">
            <div class="right_content">
                <div>
                    <div style="height: 70px;display: flex;align-items: center;margin: 0 auto;width:60%">
                        <Steps :current="current">
                            <Step title="企业入库资格审核"></Step>
                            <Step title="培育达标资格填报"></Step>
                            <Step title="资格评估"></Step>
                            <Step title="资格复核"></Step>
                        </Steps>
                    </div>
                    <div v-if="current == 0" style="margin-top:43px">
                        <template v-if="!baseForm.status">
                            <div style="margin-bottom: 35px;font-size: 24px;color: #333;text-align: center">企业入库资格信息填写</div>
                            <div style="width: 90%;background:#EAF2FB;margin:0 auto;padding-bottom: 20px">
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>企业名称：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input v-model="baseForm.companyName" placeholder='连云港某家公司名称'/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>联系人：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input v-model="baseForm.contactName" placeholder='请输入职联系人'/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>联系电话：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input v-model="baseForm.contactPhone" placeholder='请输入联系电话'/>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>企业注册地：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Select v-model="baseForm.areaName" :transfer="transfer"
                                                    style="width: 439px !important;">
                                                <Option :key="index" v-for="(value,index) in areaList" :value="value">
                                                    {{value }}
                                                </Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>统计和税务关系所在地：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Select v-model="baseForm.taxArea" :transfer="transfer"
                                                    style="width: 219px!important;">
                                                <Option :key="index" v-for="(value,index) in areaList" :value="value">
                                                    {{ value }}
                                                </Option>
                                            </Select>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Select v-if="baseForm.taxArea == '高新区'" v-model="baseForm.taxParkNew"
                                                    :transfer="transfer"
                                                    style="width: 219px!important;">
                                                <Option :key="index" v-for="(value,index) in parkList" :value="value">
                                                    {{ value }}
                                                </Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>企业注册日期：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <DatePicker type="date" :value="baseForm.regDate" @on-change="setRegDate"
                                                        placeholder="企业注册日期"></DatePicker>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>科小入库登记编号：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input v-model="baseForm.techCode" placeholder='请输入科小入库登记编号'/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>获得过高企资格：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <RadioGroup v-model="baseForm.highTechQualifi">
                                                <Radio label="1" style="margin-right: 40px">是</Radio>
                                                <Radio label="0">否</Radio>
                                            </RadioGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>产品（服务）核心技术所属领域：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Select v-model="baseForm.technicalField" :transfer="transfer"
                                                    style="width: 439px !important;">
                                                <Option :key="index" v-for="(value,index) in fieldList" :value="value">
                                                    {{value }}
                                                </Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>承诺书上传：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Upload action="" :show-upload-list="false" :before-upload="(file) => handleUpload(file,'baseForm', 'commitmentUrl')">
                                                <Button icon="ios-cloud-upload-outline" style="padding-left: 20px">选取文件</Button>
                                            </Upload>
                                            <span style="padding-left:5px;color: #EE0C0C;font-size: 12px">承诺书必须加盖公章、扫描上传彩色电子版</span>
                                        </div>
                                        <a target="_blank" href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/lyg-gqrk/%E6%89%BF%E8%AF%BA%E4%B9%A6%E6%A8%A1%E6%9D%BF.docx" download="承诺书" style="color: #1484FC;font-size: 12px">《承诺书》模板下载</a>
                                        <div class="file_hover" v-if="baseForm.commitmentUrl">
                                            <a :href="baseForm.commitmentUrl" style="font-size: 12px" target="_blank">{{baseForm.commitmentUrl.split('/').slice(-1)[0]}}</a>
                                            <Icon style="cursor: pointer" @click="delfile('baseForm','commitmentUrl')" type="md-close" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div
                                    style="border-top: none; margin-top: 46px"
                                    class="next_style common_liucheng;"
                            >
                                <div style="height: 40px; width: 120px" class="button_style" @click="Next(0)">
                                    提交审核
                                </div>
                            </div>
                        </template>
                        <template v-else>
<!--                            审核中-->
                            <template v-if="baseForm.status==1 || baseForm.status==3">
                                <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #333333;">
                                    企业入库资格信息提交成功，
                                    <span style="color: #EE0C0C">审核中······</span>
                                </div>
                                <div style="margin-top:11px;text-align:center;height: 14px;font-size: 14px;color: #333333;line-height: 14px;">
                                    （审核工作每两个月审核一次，咨询电话0518-81086771）
                                </div>
                                <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;color: #fff;margin: 21px auto;cursor: pointer" @click="$router.push({path:'/'})">
                                    返回首页
                                </div>
                            </template>
<!--                            审核不通过-->
                            <template v-if="baseForm.status==2 || baseForm.status==5">
                                <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #EE0C0C;">
                                    审核未通过
                                </div>
                                <div style="margin-top:11px;text-align:center;height: 14px;font-size: 14px;color: #EE0C0C;line-height: 14px;">
                                    原因描述：{{baseForm.status==2?reportList.oneContent:reportList.twoContent}}
                                </div>
                                <div style="text-align: center;margin: 16px 0">
                                    <div style="width: 120px;height: 40px;color: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;border:1px solid #1767E0;cursor: pointer;display: inline-block" @click="$router.push({path:'/'})">
                                        返回首页
                                    </div>
                                    <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;
                                    line-height: 40px;text-align: center;margin-left: 19px;font-size: 16px;color: #fff;cursor: pointer;display: inline-block" @click="reTest">
                                        重新申请
                                    </div>
                                </div>
                            </template>
<!--                            审核通过-->
                            <template v-if="baseForm.status==4">
                                <div style="text-align:center;height: 24px;font-size: 24px;color: #11B30B;line-height: 24px;">
                                    恭喜你，通过企业入库资格审核，请您继续提交培育资格申请
                                </div>
                                <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;color: #fff;margin: 21px auto;cursor: pointer" @click="current += 1">
                                    进入下一步
                                </div>
                            </template>
                            <div style="width: 90%;background:#EAF2FB;margin:0 auto;padding: 95px 0 122px">
                                <div style="width: 1000px;margin: 0 auto">
                                    <div style="height: 16px;font-size: 16px;font-weight: 600;color: #333333;line-height: 16px;">
                                        入库填报详情：
                                    </div>
                                    <Row class-name="rowClass" style="margin-top: 32px">
                                        <Col span="5">企业名称</Col><Col span="19">{{baseForm.companyName}}</Col>
                                        <Col span="5">联系人</Col><Col span="6">{{baseForm.contactName}}</Col><Col span="6">联系方式</Col><Col span="7">{{baseForm.contactPhone}}</Col>
                                        <Col span="5">企业注册地</Col><Col span="6">{{baseForm.areaName}}</Col><Col span="6">统计和税务关系所在地</Col><Col span="7">{{baseForm.taxPark}}</Col>
                                        <Col span="5">企业注册日期</Col><Col span="6">{{baseForm.regDate}}</Col><Col span="6">科小入库登记编号</Col><Col span="7">{{baseForm.techCode}}</Col>
                                        <Col span="5">获得过高企资格</Col><Col span="6">{{baseForm.highTechQualifi==1?'是':'否'}}</Col><Col span="6">产品（服务）核心技术所属领域</Col><Col span="7">{{baseForm.technicalField}}</Col>
                                        <Col span="5">承诺书</Col><Col span="19"><a :href="baseForm.commitmentUrl" target="_blank">{{baseForm.commitmentUrl.split('/').slice(-1)[0]}}</a></Col>

                                    </Row>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div v-if="current == 1" style="margin-top:43px">
                        <template v-if="baseForm.status == 4">
                            <div style="margin-bottom: 35px;font-size: 24px;color: #333;text-align: center">培育达标信息填写</div>
                            <div style="width: 90%;background:#EAF2FB;margin:0 auto;padding-bottom: 20px">
                                <Row>
                                    <Col span="8">
                                        <div class="table_div" style="font-weight: bold"><span style="font-size: 14px;color: #FF0000">*</span>I类知识产权数量：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two" style="color: #FF0000">（包含已下证专利和进入实质审查阶段的专利）</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>自主研发获取：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input type="number" v-model="secondForm.selfPatentCount" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>受让、受赠和并购获取：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input type="number" v-model="secondForm.tradePatentCount" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>专利总数：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input type="number" disabled v-model="totalPatentCount" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div" style="font-weight: bold"><span style="font-size: 14px;color: #FF0000">*</span>企业财务信息：</div>
                                    </Col>
                                    <Col span="16">

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>已建立研发费用辅助账：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <RadioGroup v-model="secondForm.rdCostAccount">
                                                <Radio label="1" style="margin-right: 40px">是</Radio>
                                                <Radio label="0">否</Radio>
                                            </RadioGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>近两年销售收入总额：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input type="number" v-model="secondForm.salesAmount" />万元
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>近两年研发费用总额：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input type="number" v-model="secondForm.rdAmount" />万元
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>近两年研发费用占比：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input type="number" disabled v-model="proportion" />%
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span="8">
                                        <div class="table_div" style="font-weight: bold">
                                            <span style="font-size: 14px;color: #FF0000">*</span>补充信息：
                                        </div>
                                    </Col>
                                    <Col span="16"></Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>已签约辅导机构：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <RadioGroup v-model="secondForm.auxiliary">
                                                <Radio label="1" style="margin-right: 40px">是</Radio>
                                                <Radio label="0">否</Radio>
                                            </RadioGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row v-if="secondForm.auxiliary==1">
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>签约辅导机构名称：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input v-model="secondForm.auxiliaryName" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>与高校院所建有实质性产学研合作关系：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <RadioGroup v-model="secondForm.collegeIur">
                                                <Radio label="1" style="margin-right: 40px">是</Radio>
                                                <Radio label="0">否</Radio>
                                            </RadioGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>近两年科技转化数量：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Input type="number" v-model="secondForm.iurCount" />项
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div" style="font-weight: bold"><span style="font-size: 14px;color: #FF0000">*</span>附件：</div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two" style="color: #FF0000">每类附件均可同时上传多个，附件材料必须加盖公章、扫描上传彩色电子版</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>知识产权清单、证书、缴费发票、进入实质审查通知书：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Upload
                                                    action=""
                                                    multiple
                                                    :show-upload-list="false"
                                                    :before-upload="(file) => handleUpload(file,'', 'attachurlList',1)"
                                            >
                                                <Button icon="ios-cloud-upload-outline" style="padding-left: 20px">选取文件</Button>
                                            </Upload>
                                            <span style="padding-left:5px;color: #EE0C0C;font-size: 12px">需上传汇总表及汇总表中涉及的所有证明材料</span>
                                        </div>
                                        <a target="_blank" href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/lyg-gqrk/%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E6%B8%85%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx" download="知识产权清单" style="color: #1484FC;font-size: 12px">《知识产权清单》模板下载</a>
                                        <div class="file_hover" v-if="attachurlList[1] && attachurlList[1].length" v-for="(item,index) in attachurlList[1]" :key="index">
                                            <a :href="item.url" style="font-size: 12px" target="_blank">{{item.url.split('/').slice(-1)[0]}}</a>
                                            <Icon style="cursor: pointer" @click="delmorefile('attachurlList',1,item.url)"  type="md-close" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>近两年两年科技成果转化汇总表及佐证材料：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Upload
                                                    action=""
                                                    multiple
                                                    :show-upload-list="false"
                                                    :before-upload="(file) => handleUpload(file,'', 'attachurlList',2)"
                                            >
                                                <Button icon="ios-cloud-upload-outline" style="padding-left: 20px">选取文件</Button>
                                            </Upload>
                                            <span style="padding-left:5px;color: #EE0C0C;font-size: 12px">需上传汇总表及汇总表中涉及的所有证明材料</span>
                                        </div>
                                        <a target="_blank" href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/lyg-gqrk/%E8%BF%91%E4%B8%A4%E5%B9%B4%E6%88%90%E6%9E%9C%E8%BD%AC%E5%8C%96%E6%B1%87%E6%80%BB%E8%A1%A8%E6%A8%A1%E6%9D%BF.xls" download="成果转化汇总表" style="color: #1484FC;font-size: 12px">《成果转化汇总表》模板下载</a>
                                        <div class="file_hover" v-if="attachurlList[2] && attachurlList[2].length" v-for="(item,index) in attachurlList[2]" :key="index">
                                            <a :href="item.url" style="font-size: 12px" target="_blank">{{item.url.split('/').slice(-1)[0]}}</a>
                                            <Icon style="cursor: pointer" @click="delmorefile('attachurlList',2,item.url)"  type="md-close" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>研发辅助账佐证材料：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Upload
                                                    action=""
                                                    multiple
                                                    :show-upload-list="false"
                                                    :before-upload="(file) => handleUpload(file,'', 'attachurlList',3)"
                                            >
                                                <Button icon="ios-cloud-upload-outline" style="padding-left: 20px">选取文件</Button>
                                            </Upload>
                                            <span style="padding-left:5px;color: #EE0C0C;font-size: 12px">需要上传研发辅助账的截图、财务报表等佐证材料</span>
                                        </div>
                                        <div class="file_hover" v-if="attachurlList[3] && attachurlList[3].length" v-for="(item,index) in attachurlList[3]" :key="index">
                                            <a :href="item.url" style="font-size: 12px" target="_blank">{{item.url.split('/').slice(-1)[0]}}</a>
                                            <Icon style="cursor: pointer" @click="delmorefile('attachurlList',3,item.url)"  type="md-close" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table_div"><span style="font-size: 14px;color: #FF0000">*</span>产学研合作协议、技术合同、发票：
                                        </div>
                                    </Col>
                                    <Col span="16">
                                        <div class="table_div table_div_two">
                                            <Upload
                                                    action=""
                                                    multiple
                                                    :show-upload-list="false"
                                                    :before-upload="(file) => handleUpload(file,'', 'attachurlList',4)"
                                            >
                                                <Button icon="ios-cloud-upload-outline" style="padding-left: 20px">选取文件</Button>
                                            </Upload>
                                            <span style="padding-left:5px;color: #EE0C0C;font-size: 12px">需要上传与科研院所有实质性合作的佐证材料</span>
                                        </div>
                                        <div class="file_hover" v-if="attachurlList[4] && attachurlList[4].length" v-for="(item,index) in attachurlList[4]" :key="index">
                                            <a :href="item.url" style="font-size: 12px" target="_blank">{{item.url.split('/').slice(-1)[0]}}</a>
                                            <Icon style="cursor: pointer" @click="delmorefile('attachurlList',4,item.url)" type="md-close" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style="border-top: none; margin-top: 46px" class="next_style common_liucheng;">

                                <div style="height: 40px; width: 120px;margin-left: 19px" class="button_style" @click="Next(1)">
                                    提交审核
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <!--                            审核中-->
                            <template v-if="baseForm.status==1 || baseForm.status==3">
                                <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #333333;">
                                    企业入库资格信息提交成功，
                                    <span style="color: #EE0C0C">审核中······</span>
                                </div>
                                <div style="margin-top:11px;text-align:center;height: 14px;font-size: 14px;color: #333333;line-height: 14px;">
                                    （审核工作每两个月审核一次，咨询电话：0518-81086771）
                                </div>
                                <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;color: #fff;margin: 21px auto;cursor: pointer" @click="$router.push({path:'/'})">
                                    返回首页
                                </div>
                            </template>
                            <!--                            审核不通过-->
                            <template v-if="baseForm.status==2 || baseForm.status==5">
                                <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #EE0C0C;">
                                    审核未通过
                                </div>
                                <div style="margin-top:11px;text-align:center;height: 14px;font-size: 14px;color: #EE0C0C;line-height: 14px;">
                                    原因描述：{{baseForm.status==2?reportList.oneContent:reportList.twoContent}}
                                </div>
                                <div style="text-align: center;margin: 16px 0">
                                    <div style="width: 120px;height: 40px;color: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;border:1px solid #1767E0;cursor: pointer;display: inline-block" @click="$router.push({path:'/'})">
                                        返回首页
                                    </div>
                                    <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;
                                    line-height: 40px;text-align: center;margin-left: 19px;font-size: 16px;color: #fff;cursor: pointer;display: inline-block" @click="reTest">
                                        重新申请
                                    </div>
                                </div>
                            </template>
                            <!--                            审核通过-->
                            <template v-if="baseForm.status==4">
                                <div style="text-align:center;height: 24px;font-size: 24px;color: #11B30B;line-height: 24px;">
                                    恭喜你，通过企业入库资格审核，请您继续提交培育资格申请
                                </div>
                                <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;color: #fff;margin: 21px auto;cursor: pointer" @click="current += 1">
                                    进入下一步
                                </div>
                            </template>
                        </template>
                    </div>
                    <div v-if="current == 2 || current == 3" style="margin-top:30px">
                        <!--                            审核中-->
                        <template v-if="baseForm.status==6 || baseForm.status==7">
                                <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #333333;">
                                    培育达标信息提交成功，
                                    <span style="color: #EE0C0C">审核中······</span>
                                </div>
                                <div style="margin-top:11px;text-align:center;height: 14px;font-size: 14px;color: #333333;line-height: 14px;">
                                    （审核工作每两个月审核一次，咨询电话：0518-81086771）
                                </div>
                                <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;color: #fff;margin: 21px auto;cursor: pointer" @click="$router.push({path:'/'})">
                                    返回首页
                                </div>
                            </template>
                        <!--                            审核中-->
                        <template v-if="baseForm.status==9">
                            <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #333333;">
                                培育达标资格初审通过，
                                <span style="color: #EE0C0C">正在资格复核中······</span>
                            </div>
                            <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;color: #fff;margin: 21px auto;cursor: pointer" @click="$router.push({path:'/'})">
                                返回首页
                            </div>
                        </template>
                        <!--                            审核通过-->
                        <template v-if="baseForm.status==11">
                            <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #11B303;">
                                通过资格复核，获得培育达标资格
                            </div>
                            <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;color: #fff;margin: 21px auto;cursor: pointer" @click="$router.push({path:'/'})">
                                返回首页
                            </div>
                        </template>
                        <!--                            审核不通过-->
                        <template v-if="baseForm.status==8 || baseForm.status==10">
                            <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #EE0C0C;">
                                审核未通过
                            </div>
                            <div style="margin-top:11px;text-align:center;height: 14px;font-size: 14px;color: #EE0C0C;line-height: 14px;">
                                原因描述：{{baseForm.status==8?reportList.threeContent:reportList.fourContent}}
                            </div>
                            <div style="text-align: center;margin: 16px 0">
                                <div style="width: 120px;height: 40px;color: #1767E0;border-radius: 4px;text-align: center;
                            line-height: 40px;font-size: 16px;border:1px solid #1767E0;cursor: pointer;display: inline-block" @click="$router.push({path:'/'})">
                                    返回首页
                                </div>
                                <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;
                                line-height: 40px;text-align: center;margin-left: 19px;font-size: 16px;color: #fff;cursor: pointer;display: inline-block" @click="reTestSecond">
                                    重新申请
                                </div>
                            </div>
                        </template>
                        <!--                            审核不通过-->
                        <template v-if="baseForm.status==12">
                            <div style="text-align:center;height: 24px;line-height:24px;font-size: 24px;color: #EE0C0C;">
                                复核未通过
                            </div>
                            <div style="margin-top:11px;text-align:center;height: 14px;font-size: 14px;color: #EE0C0C;line-height: 14px;">
                                原因描述：{{baseForm.fiveContent}}
                            </div>
                            <div style="text-align: center;margin: 16px 0">
                                <div style="width: 120px;height: 40px;color: #1767E0;border-radius: 4px;text-align: center;
                                line-height: 40px;font-size: 16px;border:1px solid #1767E0;cursor: pointer;display: inline-block" @click="$router.push({path:'/'})">
                                    返回首页
                                </div>
                                <div style="width: 120px;height: 40px;background: #1767E0;border-radius: 4px;
                                    line-height: 40px;text-align: center;margin-left: 19px;font-size: 16px;color: #fff;cursor: pointer;display: inline-block" @click="reTestSecond">
                                    重新申请
                                </div>
                            </div>
                        </template>
                    </div>
                    <div v-if="(current==1 && baseForm.status!=4) || current==2 || current==3" style="padding: 95px 0px 122px;background:#EAF2FB;width: 90%;margin:0 auto;">
    <!--                    入库详情-->
                        <div v-if="current==1 || (current==3 && baseForm.status==11)" style="margin-bottom: 40px" >
                            <div style="width: 1000px;margin: 0 auto">
                                <div style="height: 16px;font-size: 16px;font-weight: 600;color: #333333;line-height: 16px;">
                                    填报详情：
                                </div>
                                <Row class-name="rowClass" style="margin-top: 32px">
                                    <Col span="5">企业名称</Col><Col span="19">{{baseForm.companyName}}</Col>
                                    <Col span="5">联系人</Col><Col span="6">{{baseForm.contactName}}</Col><Col span="6">联系方式</Col><Col span="7">{{baseForm.contactPhone}}</Col>
                                    <Col span="5">企业注册地</Col><Col span="6">{{baseForm.areaName}}</Col><Col span="6">统计和税务关系所在地</Col><Col span="7">{{baseForm.taxPark}}</Col>
                                    <Col span="5">企业注册日期</Col><Col span="6">{{baseForm.regDate}}</Col><Col span="6">科小入库登记编号</Col><Col span="7">{{baseForm.techCode}}</Col>
                                    <Col span="5">获得过高企资格</Col><Col span="6">{{baseForm.highTechQualifi==1?'是':'否'}}</Col><Col span="6">产品（服务）核心技术所属领域</Col><Col span="7">{{baseForm.technicalField}}</Col>
                                    <Col span="5">承诺书</Col><Col span="19"><a :href="baseForm.commitmentUrl" target="_blank">{{baseForm.commitmentUrl.split('/').slice(-1)[0]}}</a></Col>

                                </Row>
                            </div>
                        </div>
    <!--                    培育达标资格详情-->
                        <div v-if="current==2 || current==3">
                            <div style="width: 1000px;margin: 0 auto">
                                <div style="height: 16px;font-size: 16px;font-weight: 600;color: #333333;line-height: 16px;">
                                    培育达标填报详情：
                                </div>
                                <Row class-name="rowClass" style="margin-top: 32px">
                                    <Col span="24" style="display: flex;justify-content: center;color: #333">I类知识产权数量</Col>
                                </Row>
                                <Row class-name="rowClass">
                                    <Col span="6">专利总数</Col>
                                    <Col span="18">{{secondForm.totalPatentCount}}</Col>
                                    <Col span="6">自主研发获取</Col>
                                    <Col span="6">{{secondForm.selfPatentCount}}</Col>
                                    <Col span="6">受让、受赠和并购获取</Col>
                                    <Col span="6">{{secondForm.tradePatentCount}}</Col>
                                </Row>
                                <Row class-name="rowClass">
                                    <Col span="24" style="display: flex;justify-content: center;color: #333">企业财务信息</Col>
                                </Row>
                                <Row class-name="rowClass">
                                    <Col span="6">已建立研发费用辅助账</Col><Col span="6">{{secondForm.rdCostAccount==1?'是':'否'}}</Col><Col span="6">研发费用占比</Col><Col span="6">{{secondForm.proportion}}%</Col>
                                    <Col span="6">近两年销售收入总额</Col><Col span="6">{{secondForm.salesAmount}}万元</Col><Col span="6">近两年研发费用总额</Col><Col span="6">{{secondForm.rdAmount}}万元</Col>
                                </Row>
                                <Row class-name="rowClass">
                                    <Col span="24" style="display: flex;justify-content: center;color: #333">补充信息</Col>
                                    <Row class-name="rowClass">
                                        <Col span="8">近两年科技成果转化数量</Col><Col span="4">{{secondForm.iurCount}}</Col><Col span="6">签约辅导机构</Col><Col span="6">{{secondForm.auxiliary==1?'是':'否'}}</Col>
                                        <Col span="8">与高校院所建有实质性产学研合作关系</Col><Col span="4">{{secondForm.collegeIur==1?'是':'否'}}</Col><Col span="6">签约辅导机构名称</Col><Col span="6">{{secondForm.auxiliaryName || '-'}}</Col>
                                    </Row>
                                </Row>
                                <Row class-name="rowClass">
                                    <Col span="24" style="display: flex;justify-content: center;color: #333">附件</Col>
                                    <Row class-name="rowClass">
                                        <Col span="14">知识产权清单、证书、缴费发票、进入实质审查通知书</Col>
                                        <Col span="10">
                                            <a :href="item.url" target="_blank" v-for="(item,index) in attachurlList[1]"
                                               :key="index" style="margin-right: 5px">文件{{index+1}}</a>
                                        </Col>
                                        <Col span="14">近两年两年科技成果转化汇总表及佐证材料：</Col>
                                        <Col span="10">
                                            <a :href="item.url" target="_blank" v-for="(item,index) in attachurlList[2]"
                                               :key="index" style="margin-right: 5px">文件{{index+1}}</a>
                                        </Col>
                                        <Col span="14">研发辅助账佐证材料</Col>
                                        <Col span="10">
                                            <a :href="item.url" target="_blank" v-for="(item,index) in attachurlList[3]"
                                               :key="index" style="margin-right: 5px">文件{{index+1}}</a>
                                        </Col>
                                        <Col span="14">产学研合作协议、技术合同、发票</Col>
                                        <Col span="10">
                                            <a :href="item.url" target="_blank" v-for="(item,index) in attachurlList[4]"
                                               :key="index" style="margin-right: 5px">文件{{index+1}}</a>
                                        </Col>
                                    </Row>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="enterprise-footer" style="text-align: center">
            <a style="color: #515a6e" href="https://beian.miit.gov.cn/" target="_blank">连云港科技大市场版权所有2013-2022
                苏ICP备18024573号-2. All Rights Reserved.</a>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import {
        confirmBase,
        getReportInfo,
    } from "@/plugins/api/EnterpriseCultivate";
    import {
        getEnterpriseInfo
    } from "@/plugins/api/EnterpriseExam";
    import {
        successToast,
        isNumber,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";
    import {
        uploadImg,
    } from "@/plugins/api/sciencePolicy";
    import {Message, Spin} from "view-design";

    export default {
        name: "EnterpriseCultivate",
        mounted() {
            this.getUserInfo()
        },
        computed: {
            totalPatentCount(){
                this.secondForm.totalPatentCount = parseInt(this.secondForm.selfPatentCount) + parseInt(this.secondForm.tradePatentCount)
                return parseInt(this.secondForm.selfPatentCount) + parseInt(this.secondForm.tradePatentCount)
            },
            proportion(){
                this.secondForm.proportion = parseInt(this.secondForm.rdAmount * 100 / this.secondForm.salesAmount)
                return parseInt(this.secondForm.rdAmount * 100 / this.secondForm.salesAmount)
            }
        },
        data() {
            return {
                evalId: "",
                transfer: true,
                current:-1,
                reportList: [],
                userInfo: [],
                // 第一步from
                baseForm: {
                    "id": '',  //新增无 更新传入
                    "companyName": "",
                    "contactName": "",
                    "contactPhone": "",
                    "areaName": "",
                    "taxArea": "",
                    "taxPark": "",  //连带上area的数据，不然不好根据名称联查
                    "taxParkNew": "",
                    "regDate": "",
                    "techCode": "",
                    "highTechQualifi": "",
                    "technicalField": "",
                    "commitmentUrl": "",
                    "status": 0,
                },
                secondForm:{
                    "selfPatentCount": '',
                    "tradePatentCount": '',
                    "totalPatentCount": '',
                    "rdCostAccount": "",
                    "salesAmount": "",
                    "rdAmount": "",
                    "proportion": "",
                    "auxiliary": "",
                    "auxiliaryName": "",
                    "collegeIur": "",
                    "iurCount": "",
                    //类型 1 知识产权  2成果转化 3研发佐证 4合同发票
                    // private String type;
                    //附件url
                    // private String url;
                    "attachurls": []
                },
                tab_list: [
                    {name: "高企入库培育", path: "enterprise-cultivate"},
                ],
                chooseIndex: 0,
                format: ['jpg', 'jpeg', 'png'],
                areaList: ['高新区', '新浦区', '连云区', '海州区', '赣榆区', '灌云县', '东海县', '灌南县'],
                // areaList: ['高新区'],
                parkList: ['海州工业园', '新浦工业园', '宋跳工业园'],
                fieldList: ['电子信息技术', '生物与新医药技术', '航空航天技术', '新材料技术', '高技术服务业', '新能源与节能技术', '资源与环境技术', '先进制造与自动化'],
                attachurlList:[]
            };
        },
        methods: {
            //用户信息-企业信息
            async getUserInfo() {
                getEnterpriseInfo().then((res) => {
                    if (res.code == 200) {
                        this.userInfo = res.data
                        this.getReportFun()
                    }
                });
            },
            // 注册时间
            setRegDate(date) {
                this.$set(this.baseForm, 'regDate', date)
                console.log('date', date, this.baseForm)
            },
            // 详情
            async getReportFun() {
                const json = await getReportInfo()
                if (json.code == 0) {
                    this.reportList = json.result
                    this.reportList.createTime = this.reportList.createTime ? this.reportList.createTime.split('T')[0] : ''
                    for (let item in this.baseForm) {
                        this.baseForm[item] = json.result[item]
                    }
                    for (let item in this.secondForm) {
                        this.secondForm[item] = json.result[item]
                    }
                        this.baseForm.taxParkNew = this.baseForm.taxPark?this.baseForm.taxPark.replace(this.baseForm.taxArea,''):''
                    if (!this.baseForm.companyName) {
                        this.$set(this.baseForm, 'companyName', this.userInfo.enterpriseName)
                    }
                    //调试用
                    // this.baseForm.status = '6'
                    if(this.secondForm.attachurls && this.secondForm.attachurls.length){
                        this.secondForm.attachurls.map(v=>{
                            if(!this.attachurlList[v.type])this.attachurlList[v.type] = []
                            this.attachurlList[v.type].push(v)
                        })
                    }

                    this.current = this.setCurrent()
                    console.log('this.current',this.current)
                } else {
                    this.reTest()
                }
            },
            // 提交审核 step =0第一次，step=1，第二次
            Next(step=0){
                if(step==0) {
                    //首次提交，status=1
                    this.baseForm.taxPark = this.baseForm.taxArea + this.baseForm.taxParkNew
                    for (let item in this.baseForm) {
                        if ((this.baseForm[item] === ""||this.baseForm[item] === null) && item != 'id' && item != 'status') {
                            if(this.baseForm.taxArea != '高新区' && (item == 'taxPark'||item == 'taxParkNew')){
                                continue
                            }
                            warnToast("请将数据填写完整");
                            return;
                        }
                    }
                }else{
                    let attachurlList = []
                    this.attachurlList.map((v,k)=>{
                        attachurlList.push(...v)
                    })
                    this.secondForm.attachurls = attachurlList

                    for (let item in this.secondForm) {
                        if (this.secondForm[item] === "" || this.secondForm[item] === null) {
                            if(item == 'auxiliaryName' && this.secondForm.auxiliary == 0){
                                //已签约辅导机构为否，签约辅导机构名称可以为空
                                continue
                            }
                            warnToast("请将数据填写完整");
                            return;
                        }
                    }
                    if(!this.secondForm.attachurls.length){
                        warnToast("请上传文件");
                        return;
                    }
                    //第二次提交，status=6
                }

                this.$Modal.confirm({
                    draggable: true,
                    okText:'确定无误',
                    cancelText:'再次检查',
                    title:'请确认',
                    onOk: async () => {
                        this.Nextsubmit(step)
                    },
                    onCancel:async () => {
                    },
                    render: (h) => {
                        return h('div',
                            [h('div', {
                                style: "text-align:center;margin-bottom:20px;padding-top:20px;vertical-align: middle;font-size: 16px;color: #17233d;",
                            }, '内容填写是否真实无误')
                            ])
                    }
                })
            },
            async Nextsubmit(step) {
                let params = Object.assign(this.baseForm,this.secondForm)
                if(step==0){
                    params.status = 1
                }else{
                    params.status = 6
                }
                let json = await confirmBase(params);
                if (json.code == 0) {
                    if(step==0){
                        this.$set(this.baseForm,'status','1')
                    }else{
                        this.$set(this.baseForm,'status','6')
                    }
                    this.current = this.setCurrent()
                    this.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message)
                }
            },
            // 重新提交，返回第2次编辑的页面
            reTestSecond(){
                this.current = 0;
                this.changeHeight();
                this.secondForm = {
                    "selfPatentCount": '',
                    "tradePatentCount": '',
                    "totalPatentCount": '',
                    "rdCostAccount": "",
                    "salesAmount": "",
                    "rdAmount": "",
                    "proportion": "",
                    "auxiliary": "",
                    "auxiliaryName": "",
                    "collegeIur": "",
                    "iurCount": "",
                    "attachurls": []
                }
                this.baseForm.status = 4
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            // 重新提交，返回第1次编辑的页面
            reTest() {
                this.current = 0;
                this.changeHeight();
                this.baseForm = {
                    "id": this.baseForm.id,  //新增无 更新传入
                    "companyName": this.baseForm.companyName,
                    "contactName": "",
                    "contactPhone": "",
                    "areaName": "",
                    "taxArea": "",
                    "taxPark": "",  //连带上area的数据，不然不好根据名称联查
                    "taxParkNew": "",
                    "regDate": "",
                    "techCode": "",
                    "highTechQualifi": "",
                    "technicalField": "",
                    "commitmentUrl": "",
                    "status": "",
                }
                this.secondForm = {
                    "selfPatentCount": '',
                    "tradePatentCount": '',
                    "totalPatentCount": '',
                    "rdCostAccount": "",
                    "salesAmount": "",
                    "rdAmount": "",
                    "proportion": "",
                    "auxiliary": "",
                    "auxiliaryName": "",
                    "collegeIur": "",
                    "iurCount": "",
                    "attachurls": []
                },
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            showTab(index) {
                this.chooseIndex = index
                this.$router.push({
                    path: this.tab_list[index].path
                })
            },
            changeHeight() {
                let that = this;
                this.$nextTick(() => {
                    that.tab_height =
                        Number($(".right_content_parent").height()) -
                        (Number(document.body.clientHeight) - 101) >
                        20
                            ? $(".right_content_parent").height() + "px"
                            : Number(document.body.clientHeight) - 101 + "px";
                });
            },
            // 通过status判断当前是第几步
            setCurrent(){
                // 1第一次审核中 2第一次审核不通过 3第一次审核通过、第二次审核中 4第二次审核通过 5第二次审核不通过
                // 6第三次审核中  7第三次审核通过 8第三次审核不通过  9第四次审核通过，第五次审核中 10第四次审核不通过
                // 11第五次审核通过 12第五次审核不通过
                switch (this.baseForm.status) {
                    case '1':
                    case '2':
                    case '3':
                    case '5':
                        return 0
                    case '4':
                        return 1
                    case '6':
                    case '7':
                    case '8':
                    case '10':
                        return 2
                    case '9':
                    case '11':
                    case '12':
                        return 3
                    default:
                        return 0
                }
            },
            // 多文件上传，删除已上传文件
            delmorefile(name,key,url){
                let arr = this.attachurlList[key]
                let newarr = arr.splice(arr.findIndex(item => item.url === url), 1)
                this.attachurlList[key] = arr
                this.$forceUpdate()
            },
            //但文件上传，删除已上传文件
            delfile(name,key){
                this[name][key] = ''
            },
            //  上传
            async handleUpload(file,key, name,type=0) {
                const FileExt = file.name.replace(/.+\./, ""); //取得文件的后缀名
                console.log('FileExt',FileExt)
                if (FileExt == "png" || FileExt == "jpg"|| FileExt == "doc"|| FileExt == "docx"|| FileExt == "pdf"|| FileExt == "xls"|| FileExt == "xlsx") {
                    const reader = new FileReader();
                    //将文件读取为 DataURL 以data:开头的字符串
                    let code = "";
                    let _that = this;
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
                        if(e.total > 8 * 1024 * 1024){
                            warnToast('上传文件不能大于8M')
                            return
                        }
                        code = e.target.result;
                        let data = {
                            imageBase64: code,
                            dataType: 0,
                        };
                        uploadImg(data,true).then((res) => {
                            // successToast("上传成功")
                            if (res.code == "200") {
                                if(type>0){
                                    // 多文件上传
                                    if(!_that[name][type]){
                                        _that[name][type] = []
                                    }
                                    _that[name][type].push({type,url:res.data})
                                    console.log('_that[name]',_that[name])
                                   _that.$forceUpdate()
                                }else {
                                    _that[key][name] = res.data;
                                }
                                Spin.hide()
                            } else {
                                errorToast(res.message);
                            }
                        });
                    };
                }else{
                    warnToast('请上传jpg,png,doc,docx,pdf,xls,xlsx格式文件')
                }
                return false
                // if(json.code == 200){

                // }
            },
        },
    };
</script>

<style scoped lang="scss">

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    .table_div {
        height: 60px;
        line-height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #333333;
    }

    .table_div_two {
        border-right: none !important;
        justify-content: flex-start;
    }

    .arrow_ld {
        color: white !important;
        display: inline-block;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        line-height: 14px;
        text-align: center;
    }

    .div_first_td {
        color: white;
    }

    .table-box {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgb(218, 226, 229);
        border-right: 1px solid rgb(218, 226, 229);
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .table-box2 {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgb(218, 226, 229);
        border-right: 1px solid rgb(218, 226, 229);
        font-size: 14px;
    }

    .table-box-double {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-bottom: 1px solid rgb(218, 226, 229);
        border-right: 1px solid rgb(218, 226, 229);
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
    }

    .ivu-progress {
        display: inline-block !important;
        width: 65% !important;
    }

    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted rgb(218, 226, 229);
    }

    .neirong {
        margin-left: 130px;

        .download_span {
            color: blue;
            display: inline-block;
            margin-right: 30px;
        }

        p {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
        }
    }

    .bottom_style {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: -110px;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        overflow: hidden;
    }

    .right_header {
        width: 95%;
        padding-top: 20px;
        height: 140px;
        position: relative;
        margin-bottom: 40px !important;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #f5f5f5;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        display: inline-block;
        background-color: #1767E0;
        z-index: 51;
        text-align: center;
        font-size: 16px;
        color: white;
        cursor: pointer;
        line-height: 40px;
        height: 40px;
        border-radius: 4px;
    }

    .right_content {
        width: 100%;
        margin: 0 auto;
        /*border-width: 1px;*/
        /*border-color: rgb(218, 226, 229);*/
        /*border-style: solid;*/
        //   background-color: rgb(255, 255, 255);
        //   min-width: 900px;
        //   height: auto;
    }

    .right_content_parent {
        padding-top: 50px;
        margin-bottom: 130px;
        min-width: 1210px;
        position: relative;
        width: calc(100% - 160px);
        margin-left: 160px;
        min-height: calc(100% - 46px);
    }

    .left_tab_parent {
        height: 100%;
        min-width: 160px;
        width: 160px;
        background-color: #D5E7FA;
        position: fixed;
        top: 101px;

        .left_tab {
            div {
                height: 61px;
                line-height: 61px;
                font-size: 14px;
                color: #597697;
                padding-left: 10px;
                cursor: pointer;
                border-bottom: 1px solid #BDDBFA;

                &.isChoose {
                    color: #fff;
                    background-color: #1767E0;
                }
            }
        }
    }

    .hidden_span {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        white-space: pre-wrap;
        line-height: normal;
        margin-left: 10px;
        color: #333333;
    }

    .two-title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 0 auto;
        font-weight: bold;
    }

    .two-row {
        width: 500px;
        height: 16px;
        line-height: 16px;
        margin: 14px 0 14px 30%;
        display: inline-block;
    }

    .two-item {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333333;
    }

    /deep/ .ivu-input-wrapper{
        width: 439px;
    }
    /deep/ .ivu-input {
        width: 439px;
        height: 40px !important;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0 10px;
    }

    /deep/ .ivu-select {
        // border-radius: 4px !important;
        // width: 188px;
    }

    /deep/ .ivu-steps-item {
        overflow: visible;

        .ivu-steps-main {
            display: block;
            margin-top: 20px;

            .ivu-steps-title {
                transform: translate(-50%);
                position: relative;
                left: 12px;
            }
        }

        .ivu-steps-title, .ivu-steps-head {
            background: transparent;
        }

        .ivu-steps-tail {
            padding: 0px 14px;
        }

        &.ivu-steps-status-process, &.ivu-steps-status-finish {
            .ivu-steps-head {
                &:after {
                    border: 1px solid #2d8cf0;
                }
            }
        }

        &.ivu-steps-status-finish {
            .ivu-steps-title {
                color: #2d8cf0 !important;
            }
        }

        &.ivu-steps-status-wait {
            .ivu-steps-head-inner {
                background-color: #ccc;

                span {
                    color: #fff;
                }
            }
        }

        .ivu-steps-head {
            position: relative;
            padding-left: 0 !important;
            transform: scale(1.5);

            &:after {
                position: absolute;
                content: '';
                width: 30px;
                height: 30px;
                border: 1px solid #ccc;
                border-radius: 50%;
                display: inline-block;
                top: -2px;
                left: -2px;
            }
        }
    }
    .rowClass{
        background: #fff;
        border-top: 1px solid #D0D0D0;
        border-left: 1px solid #D0D0D0;
        .ivu-col{
            min-height: 60px;
            line-height: 24px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #D0D0D0;
            border-right: 1px solid #D0D0D0;
            padding: 0 10px;
            font-size: 14px;
            &:nth-child(odd){
                background: #FAFAFA;
                color: #A4A4A4;
            }
            &:nth-child(even){
                color: #333333;
            }
        }
    }
    .file_hover{
        height: 24px;
        width: 400px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        &:hover{
            background: rgba(15, 97, 223, 0.1);
        }
    }
</style>
