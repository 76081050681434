import {imgUrl} from "../http/baseUrl";
import {get,post,del,put} from "../http/http";

//测评 新增/更新
export const confirmBase=(params)=> {
  return post(`${imgUrl}/v1/qualifi`,params, {hideLoading: true});
}

//测评列表
export const getReportInfo=(params)=> {
  return get(`${imgUrl}/v1/qualifiDetail`,params, {hideLoading: false, hideError: true});
}
